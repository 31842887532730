.btn, input {
  box-shadow: none !important;
}

@import "~bootstrap/scss/bootstrap";

html, body {
  height: 100%;
  overflow: hidden;
}
